import { boatServiceApi } from '@/shared/api/boat-service.api';
import { userServiceApi } from '@/shared/api/user-service.api';
import { AppStoreTag } from '@/shared/constants/app-store-tag.constant';
import { convertTimezone } from '@/shared/lib/helpers/convert-timezone.helper';
import { dateFormat } from '@/shared/lib/helpers/date-format.helper';

import {
	CredsEmailApiVariables,
	PaginationUser,
	PaginationUserResponse,
	UserApiVariables,
	UserFilterVariables,
	UserFilterVariablesResponse,
	UserPagination,
	UsersApiVariables,
} from '../model/types/users-management-api.interface';
import { parseUserFilterVariables } from '../model/utils/parse-user-filter-variables.util';

const usersManagementApi = userServiceApi.injectEndpoints({
	endpoints: (build) => ({
		getUsers: build.query<UserPagination<PaginationUser>, UsersApiVariables>({
			providesTags: [AppStoreTag.Users],
			query: ({ page, size, value, sortBy, orderBy, userTypes, roles, sectors, activeSources }) => {
				const params = new URLSearchParams(
					Object.entries({
						page: String(page),
						size: String(size),
						search: value,
						sort_by: sortBy,
						order_by: orderBy,
					}),
				);

				if (userTypes) params.append('user_types', userTypes);
				if (roles) params.append('roles', roles);
				if (sectors) params.append('sectors', sectors);
				if (activeSources) params.append('active_sources', activeSources);

				return {
					method: 'GET',
					url: `user/list?${params}`,
				};
			},
			transformResponse: (response: UserPagination<PaginationUserResponse>): UserPagination<PaginationUser> => ({
				...response,
				items: response.items.map((data) => ({
					id: String(data.id),
					surname: data.surname,
					name: data.name,
					username: data.username,
					company: data.company,
					sectors: data?.sectors || [],
					activeSources: data?.active_sources || [],
					userType: data.user_type,
					status: data.status,
					role: data.role,
					isCredSent: data.are_creds_sent,
					editDashboard: data.edit_dashboard || false,
					email: data.email,
				})),
			}),
		}),
		getUser: build.query<PaginationUser, UserApiVariables>({
			providesTags: [AppStoreTag.UserDetail],
			query: ({ id }) => ({
				method: 'GET',
				url: `user/get/${id}`,
			}),
			transformResponse: (response: PaginationUserResponse, _, { timezone }): PaginationUser => ({
				id: String(response.id),
				surname: response.surname,
				name: response.name,
				username: response.username,
				company: response.company,
				sectors: response?.sectors || [],
				activeSources: response?.active_sources || [],
				userType: response.user_type,
				isActive: response.is_active,
				role: response.role,
				status: response.status,
				isCredSent: response.are_creds_sent,
				editDashboard: response.edit_dashboard,
				email: response.email,
				acceptedPrivacyPolicyVersion: response.accepted_privacy_policy_version,
				acceptedTermsAndConditionsVersion: response.accepted_terms_and_conditions_version,
				acceptedTimeTC:
					response.tc_accepted_time &&
					dateFormat(convertTimezone(response.tc_accepted_time, timezone), {
						format: 'full',
						dateSeparator: '/',
						separator: '',
					}),
				acceptedTimePP:
					response.pp_accepted_time &&
					dateFormat(convertTimezone(response.pp_accepted_time, timezone), {
						format: 'full',
						dateSeparator: '/',
						separator: '',
					}),
			}),
			async onQueryStarted(variables, { queryFulfilled, dispatch }) {
				try {
					await queryFulfilled;
					dispatch(boatServiceApi.util.invalidateTags([AppStoreTag.Boat, AppStoreTag.BoatDetail]));
				} catch {}
			},
		}),
		getUsersFilters: build.query<UserFilterVariables, void>({
			query: () => ({
				method: 'GET',
				url: 'user/list/filters',
			}),
			transformResponse: (response: UserFilterVariablesResponse) => parseUserFilterVariables(response),
		}),
		sendCredsEmail: build.mutation<string, CredsEmailApiVariables>({
			invalidatesTags: [AppStoreTag.Users],
			query: (body) => ({
				method: 'POST',
				url: 'user/send-creds',
				body,
			}),
		}),
	}),
});

export const { useGetUsersQuery, useGetUserQuery, useGetUsersFiltersQuery, useSendCredsEmailMutation } =
	usersManagementApi;
